/* eslint-disable no-nested-ternary */
import {
    Box,
    /* Collapse, */
    Divider,
    Drawer,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    /* SvgIcon, */
    useTheme
} from '@material-ui/core';
/* import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FaceIcon from '@material-ui/icons/Face'; */
import clsx from 'clsx';
/* import { useState } from 'react'; */
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    SIDEBARWIDTH,
    SIDEBARHEIGHT
} from '../../utils/constants';
/* import useUserInfo from '../../hooks/swr/useUserInfo'; */
import { ReactComponent as LogoutCircleRFill } from '../../assets/images/logout-circle-r-fill.svg';
import { Store3Fill } from '../../components/icons';

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: SIDEBARWIDTH,
            flexShrink: 0
        }
    },
    drawerPaper: {
        width: SIDEBARWIDTH,
        height: SIDEBARHEIGHT,
        top: '113px',
        borderRadius: '0px 5px 5px 0px',
        boxShadow:
            '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        border: 0,
        overflow: 'initial',

        [theme.breakpoints.down('xs')]: {
            height: '82%'
        }

        /*  '&.profile': {
          height: '100%',
        }, */
    },
    nested: {
        paddingLeft: theme.spacing(2)
    },
    noUnderline: {
        '& ul a': {
            textDecoration: 'none',
            color: theme.palette.text.primary
        }
    },
    menuListItem: {
        flexDirection: 'column',
        textAlign: 'center',
        position: 'relative',

        '&::after': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            height: '100%',
            width: '3px',
            borderRadius: '5px 0px 0px 5px',
            background: '#0054A5',
            border: '1.5px solid #0054A5',
            display: 'none'
        },

        '& > a': {
            textDecoration: 'none'
        },

        '&:hover, &.Mui-selected': {
            background: 'transparent !important',

            '& > .MuiListItemIcon-root': {
                '& svg': {
                    fill: '#0054A5'
                }
            },

            '& .MuiTypography-body1': {
                color: '#0054A5'
            },

            '&::after': {
                display: 'block'
            }
        },

        '& > .MuiListItemIcon-root': {
            justifyContent: 'center',
            marginBottom: '6px',

            '& svg': {
                fill: '#9BA6B2'
            }
        },

        '& .MuiTypography-body1': {
            color: '#9BA6B2',
            fontSize: '1.094rem',
            lineHeight: '16px'
        },

        '& .arrowForward': {
            position: 'absolute',
            right: '6px',
            top: '50%',
            transform: 'translateY(-50%)',
            fontSize: '1rem',
            fill: '#9BA6B2'
        }
    },
    appSidebarT: {
        height: '100%',
        overflowY: 'auto',

        /* [theme.breakpoints.down('xs')]: {
            height: '75%'
        }, */

        '& .MuiCollapse-container': {
            padding: 0,

            '& .MuiList-root': {
                padding: 0,

                '& .MuiListItem-root': {
                    justifyContent: 'center',
                    textAlign: 'center',
                    paddingTop: '2px',
                    paddingBottom: '2px'
                }
            }
        },

        '&.profile': {
            height: '100%'
        }
    },
    subMenu: {
        zIndex: '999',

        '& .MuiPaper-root': {
            boxShadow: '0px 4px 8px rgba(16, 24, 64, 0.08)',
            borderRadius: '5px',
            border: 0,

            '& .MuiList-root': {
                padding: 0
            },

            '& .MuiListItem-root': {
                color: '#9BA6B2',
                fontSize: '1.094rem',
                textAlign: 'left',
                alignItems: 'flex-start',
                paddingLeft: '24px',
                paddingRight: '24px',

                '&:hover, &.Mui-selected': {
                    background: '#FEFAFB !important',
                    color: '#D81A1D'
                }
            }
        }
    }
}));

export default function AppSidebar({ drawerToggle, menuOpen, rootRef }) {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation('common');
    const { pathname } = useLocation();
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const checkIfMouseEnterOutside = (e) => {
            if (open && rootRef.current && rootRef.current.contains(e.target)) {
                setOpen(false);
            }
        };

        setTimeout(() => {
            document.addEventListener('mouseout', checkIfMouseEnterOutside);
        }, 1000);

        return () => {
            document.removeEventListener('mouseout', checkIfMouseEnterOutside);
        };
    }, [open]);

    /* const [isExpandedMore, setIsExpandedMore] = useState(false); */

    const logOut = () => {
        // *remove user info from localStorage to prevent redirect when user not authenticated actually
        localStorage.removeItem('user');
        history.push('/');
    };

    /*  const handleSwitchFacility = () => {
        history.push({
            pathname: '/facilities',
            state: { rewrite: false }
        });
    }; */

    /* const { isLoading, userInfo } = useUserInfo(); */

    /* const navigateUserEdit = () => {
        /* setIsSideBarExpanded(false); // FIXME:
        setIsExpandedMore(false);
        // ! Page is not ready, yet
        // TODO: Add userProfilePage
        // history.push('/user-profile');
    }; */

    const drawer = (
        <>
            <Box
                display="flex"
                flexDirection="column"
                className={classes.noUnderline}
                style={{ height: 'inherit' }}
            >
                <Box
                    position="relative"
                    className={clsx(classes.appSidebarT, {
                        // profile: isUserProfileRelatedPage //FIXME:
                        test: '' // FIXME:
                    })}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        className={classes.noUnderline}
                    >
                        <List>
                            <Link to="/facilities">
                                <ListItem
                                    button
                                    selected={pathname === '/facilities'}
                                    className={classes.menuListItem}
                                >
                                    <ListItemIcon>
                                        <Store3Fill width="22" height="22" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('facilities')} />
                                </ListItem>
                            </Link>
                        </List>
                        {/*  <List>
                            <Link to="/dashboard">
                                <ListItem
                                    button
                                    selected={pathname === '/dashboard'}
                                    className={classes.menuListItem}
                                >
                                    <ListItemIcon>
                                        <SvgIcon>
                                            <path
                                                fill="currentColor"
                                                d="M6 19H8V21H6V19M12 3L2 8V21H4V13H20V21H22V8L12 3M8 11H4V9H8V11M14 11H10V9H14V11M20 11H16V9H20V11M6 15H8V17H6V15M10 15H12V17H10V15M10 19H12V21H10V19M14 19H16V21H14V19Z"
                                            />
                                        </SvgIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Storage Area" />
                                </ListItem>
                            </Link>
                        </List> */}

                        {/* <List>
                            <ListItem
                                button
                                onClick={() =>
                                    setIsExpandedMore(
                                        (previousState) => !previousState
                                    )
                                }
                                className={classes.menuListItem}
                            >
                                <ListItemText primary="Account" />
                                {isExpandedMore ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItem>

                            <Collapse in={isExpandedMore} timeout="auto">
                                <List disablePadding className={classes.nested}>
                                    <ListItem
                                        button
                                        onClick={navigateUserEdit}
                                        selected={pathname === '/user-profile'}
                                    >
                                        <ListItemIcon>
                                            <FaceIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                isLoading
                                                    ? 'Loading...'
                                                    : userInfo?.fullName
                                            }
                                        />
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={handleSwitchFacility}
                                    >
                                        <ListItemText primary="Switch Facility" />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List> */}
                    </Box>
                </Box>

                <Divider style={{ width: '80%', margin: '0 auto' }} />

                <Box
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%'
                    }}
                >
                    <ListItem
                        button
                        onClick={logOut}
                        className={classes.menuListItem}
                    >
                        <ListItemIcon>
                            <LogoutCircleRFill width={20} height={20} />
                        </ListItemIcon>
                        <ListItemText primary={t('logOut')} />
                    </ListItem>
                </Box>
            </Box>
        </>
    );

    return (
        <>
            <nav className={classes.drawer} aria-label="Sidebar">
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={menuOpen}
                        onClose={drawerToggle}
                        classes={{
                            paper: `${classes.drawerPaper} ${
                                // isUserProfileRelatedPage ? 'profile' : '' FIXME:
                                ''
                            }`
                        }}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: `${classes.drawerPaper} ${
                                // isUserProfileRelatedPage ? 'profile' : '' //FIXME:
                                ''
                            }`
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </>
    );
}
