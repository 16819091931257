import useSWR from 'swr';
import { SMARTBE_APP_ID } from '../../utils/constants';
import workybeApiFetcher from '../../utils/fetchers/workybeApiFetcher';

const revalidationOptions = {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false,
};

// @v2ApiFetcher: fetch function using v2ApiFetcher config
export default function useGetSideApps() {
    const { data, error, mutate } = useSWR(
        `/Company/GetSideApps?appId=${SMARTBE_APP_ID}`,
        workybeApiFetcher,
        revalidationOptions
    );

    // @companies: companies details data returned from the API
    // @isLoading: to be able to show loading effect, can be implemented later directly
    // @isError: error state for the API call
    return {
        companies: data,
        companiesIsLoading: !error && !data,
        companiesIsError: error,
        companyMutate: mutate
    };
}
