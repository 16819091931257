import { createMuiTheme } from '@material-ui/core/styles';
import coreTheme from './coreTheme';

// Create a light theme instance.
const lightTheme = createMuiTheme({
    ...coreTheme,
    palette: {
        ...coreTheme.palette,
        type: 'light'
    }
});

export default lightTheme;
