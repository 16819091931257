export const SMARTBE_APP_ID = 'b5662b9e-1f4a-4a1a-bf13-e70af0261212';
export const SIDEBARWIDTH = 110;
export const SIDEBARHEIGHT = '86%';
export const DRAWERWIDTH = 350;
export const SENSOR_STATUS = {
    Broken: 0,
    Normal: 1,
    Low_Anomaly: 2,
    High_Anomaly: 3,
    Offline: 4
};
export const DEVICE_STATUS = {
    Broken: 0,
    Working: 1,
    Paused: 2
};
export const MODULE_TYPE = {
    Standart: 'MLI-n666kycef3l',
    Status: 'MLI-foa6kilxjaj',
    Control: 'MLI-6oj1vfafw5',
    Progress: 'MLI-72w97yt015a'
};
export const DOMAIN = {
    SmartbeProd: 'smartapp.workybe.com',
    SmartbeTest: 'smartapp-test.workybe.com',
    Alize: 'iot.alizemhs.com'
};
