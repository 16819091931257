import { Box, Hidden, makeStyles } from '@material-ui/core';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import shouldRenderAppBar from '../utils/shouldRenderAppBar';
import shouldRenderHeader from '../utils/shouldRenderHeader';
import AppHeader from './AppHeader/AppHeader';
import AppSidebar from './AppSideBar/AppSideBar';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    toolbar: {
        ...theme.mixins.toolbar,

        [theme.breakpoints.up('sm')]: {
            minHeight: '66px'
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(4)
    }
}));

export default function AppLayout({ children }) {
    const classes = useStyles();
    const { pathname } = useLocation();
    const ref = useRef();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className={classes.root} ref={ref}>
            {/* shouldRenderAppBar decides to render AppBar */}
            {shouldRenderAppBar(pathname) && (
                <AppHeader drawerToggle={handleDrawerToggle} />
            )}

            {/* shouldRenderAppBar decides to render SideBar */}
            {shouldRenderAppBar(pathname) && (
                <AppSidebar
                    menuOpen={menuOpen}
                    drawerToggle={handleDrawerToggle}
                    rootRef={ref}
                />
            )}

            <main className={classes.content}>
                {shouldRenderAppBar(pathname) &&
                    // *shouldRenderHeader decides to hide Toolbar or not
                    (shouldRenderHeader(pathname) ? (
                        <div className={classes.toolbar} />
                    ) : (
                        <Hidden smUp>
                            <div className={classes.toolbar} />
                        </Hidden>
                    ))}

                <Box mt={shouldRenderAppBar(pathname) ? 2 : 0}>{children}</Box>
            </main>
        </div>
    );
}
