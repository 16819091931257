import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Grid,
    Paper,
    makeStyles,
    Slide,
    Button,
    Card,
    CardHeader,
    CardActions
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useGetSideApps from '../../hooks/swr/useGetSideApps';

const useStyles = makeStyles((/* theme */) => ({
    root: {
        padding: '1.875rem 2.5rem',
        boxShadow: 'none'
    },
    company: {
        padding: '24px',
        boxShadow: '0px 1px 4px -1px rgba(28, 48, 74, 0.5)',
        height: 193,
        position: 'relative',

        '& .MuiCardHeader-root': {
            padding: 0
        }
    },
    avatar: {
        width: '60px',
        height: '60px',

        '& img': {
            maxWidth: '100%',
            height: 'auto',
            display: 'block'
        }
    },
    companyTitle: {
        color: '#0054A5',
        marginBottom: '2px',
        fontWeight: 400
    },
    cardFooterAction: {
        position: 'absolute',
        bottom: 20,
        padding: 0,
        width: '100%',

        '& button': {
            width: '88%'
        }
    }
}));

export default function CompanyPage() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation('common');

    const { companies, companiesIsLoading } = useGetSideApps();

    const handleClickView = (company) => {
        const userInfo = JSON.parse(localStorage.getItem('user'));

        userInfo.companyId = company.id;
        userInfo.companyName = company.company;
        localStorage.setItem('user', JSON.stringify(userInfo));

        history.push({
            pathname: `/facilities`
        });
    };

    useEffect(() => {
        const { state: { rewrite } = false } = location;

        if (companies && companies.length === 1 && rewrite) {
            handleClickView(companies[0]);
        }
    }, [companies]);

    if (companiesIsLoading) {
        return (
            <p>Data is loading...</p>
        ); /* TODO: @ayhan her bileşende kullanmamak için genel bir HOC komponent içine sarmalı */
    }

    return (
        <>
            <Slide direction="down" in>
                <Paper className={classes.root}>
                    <Grid container spacing={2}>
                        {companies?.map((c) => (
                            <Grid key={c.id} item xs={12} sm={6} md={4} xl={3}>
                                <Card className={classes.company}>
                                    <CardHeader
                                        /* avatar={
                                        <Avatar
                                            aria-label="Facility"
                                            className={classes.avatar}
                                        >
                                            {c.facility_details.facility_image
                                                .filePath && (
                                                <img
                                                    src={`${c.facility_details.facility_image.filePath}`}
                                                    alt={`${c.facility_details.facility_name}`}
                                                />
                                            )}
                                        </Avatar>
                                    } */
                                        titleTypographyProps={{
                                            variant: 'h6',
                                            className: classes.companyTitle
                                        }}
                                        title={`${c.company}`}
                                    />
                                    <CardActions
                                        className={classes.cardFooterAction}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            onClick={() => handleClickView(c)}
                                        >
                                            {t('view')}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Slide>
        </>
    );
}
