/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import common_en from './translations/en/common.json';
import common_tr from './translations/tr/common.json';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            detection: {
                order: [
                    'navigator',
                    'querystring',
                    'cookie',
                    'localStorage',
                    'sessionStorage',
                    'htmlTag',
                    'path',
                    'subdomain'
                ]
            },
            fallbackLng: 'en-gb',
            debug: true,
            // lng: 'en-gb',<--- turn off for detection to work
            resources: {
                en: {
                    common: common_en
                },
                tr: {
                    common: common_tr
                }
            },
            interpolation: {
                escapeValue: false // not needed for react as it escapes by default
            },
            react: {
                useSuspense: false,
                bindI18n: 'languageChanged loaded'
            }
        },
        // eslint-disable-next-line prettier/prettier
        (err, t) => {}
    );

export default i18n;
