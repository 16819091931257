import useSWR from 'swr';
import v2ApiFetcher from '../../api/oldv2Api';

// @v2ApiFetcher: fetch function using v2Api config
export default function useUserInfo() {
    /* const token = JSON.parse(localStorage.getItem('user') || ''); */

    const { data, error } = useSWR(['/User/GetUserProfile'], v2ApiFetcher);

    // @userInfo: data returned from the API
    // @isLoading: to be able to show loading effect, can be implemented later directly
    // @isError: error state for the API call
    return {
        userInfo: data?.data,
        isLoading: !error && !data,
        isError: error
    };
}
