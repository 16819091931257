import { CssBaseline, IconButton, ThemeProvider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { SnackbarProvider } from 'notistack';
import { createRef, lazy, Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import lightTheme from './assets/styles/themes/lightTheme';
import './assets/styles/scss/index.scss';
import AppLayout from './layout/AppLayout';
import RenderRoute from './routes/RenderRoute';
import i18n from './i18n';
import OverridesMUIGlobal from './assets/styles/themes/overridesMUIGlobal';
import 'react-quill/dist/quill.snow.css';
import CompanyPage from './pages/CompanyPage/CompanyPage';
import { DOMAIN } from "./utils/constants";

const DashboardPage = lazy(() => import('./pages/DashboardPage/DashboardPage'));
const DeviceDetailPage = lazy(() =>
    import('./pages/DeviceDetailPage/DeviceDetailPage')
);
const ImportPage = lazy(() => import('./pages/ImportPage/ImportPage'));
const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'));
const SignUpPage = lazy(() => import('./pages/SignUpPage/SignUpPage'));
const UserProfilePage = lazy(() =>
    import('./pages/UserProfilePage/UserProfilePage')
);
const FacilityPage = lazy(() => import('./pages/FacilityPage/FacilityPage'));
const NewFacility = lazy(() => import('./pages/FacilityPage/view/NewFacility'));
const DeviceList = lazy(() => import('./pages/DevicePage/view/DeviceList'));

export default function App() {
    // *add action to all snackbars
    const notistackRef = createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    const faviconByDomainName = () => {
        const hostName = window.location.hostname;
        let favicon = '';

        switch (hostName) {
            case DOMAIN.Alize:
                favicon = `alize-favicon.ico`;
                break;

            default:
                favicon = `favicon.ico`;
                break;
        }

        return favicon;
    }

    const titleByDomainName = () => {
        const hostName = window.location.hostname;
        let title = '';

        switch (hostName) {
            case DOMAIN.Alize:
                title = `Alize`;
                break;

            default:
                title = `SmartBe`;
                break;
        }

        return title;
    }

    useEffect(() => {
        const favicon = document.getElementById('favicon');

        favicon.href = faviconByDomainName();
        document.title = titleByDomainName();
    }, [])

    return (
        <I18nextProvider i18n={i18n}>
            {/* *provides some overrides on Material-UI components and default
            values, and an able implement darkTheme later */}
            <ThemeProvider theme={lightTheme}>
                <CssBaseline />
                <OverridesMUIGlobal />
                {/* 
				//*snackbar provider
			*/}
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    iconVariant={{
                        error: (
                            <ErrorIcon
                                fontSize="small"
                                style={{ marginRight: 8 }}
                            />
                        )
                    }}
                    ref={notistackRef}
                    action={(key) => (
                        <IconButton
                            onClick={onClickDismiss(key)}
                            color="inherit"
                            aria-label="dismiss"
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                >
                    <BrowserRouter>
                        {/* 
					// *AppLayout responsible from AppBar, SideBar render logic
				*/}

                        <Suspense fallback={<div>Page is Loading...</div>}>
                            <AppLayout>
                                <Switch>
                                    <RenderRoute
                                        path="/login"
                                        exact
                                        component={LoginPage}
                                    />
                                    <RenderRoute
                                        path="/signup"
                                        exact
                                        component={SignUpPage}
                                    />
                                    <RenderRoute
                                        path="/dashboard"
                                        exact
                                        isProtectedRoute
                                        component={DashboardPage}
                                    />
                                    <RenderRoute
                                        path="/user-profile"
                                        exact
                                        isProtectedRoute
                                        component={UserProfilePage}
                                    />
                                    <RenderRoute
                                        path="/device-detail/:deviceId"
                                        exact
                                        isProtectedRoute
                                        component={DeviceDetailPage}
                                    />
                                    <RenderRoute
                                        path="/import"
                                        exact
                                        isProtectedRoute
                                        component={ImportPage}
                                    />
                                    <RenderRoute
                                        path="/companies"
                                        exact
                                        isProtectedRoute
                                        component={CompanyPage}
                                    />
                                    <RenderRoute
                                        path="/facilities"
                                        exact
                                        isProtectedRoute
                                        component={FacilityPage}
                                    />
                                    <RenderRoute
                                        path="/facility"
                                        exact
                                        isProtectedRoute
                                        component={NewFacility}
                                    />
                                    <RenderRoute
                                        path="/device-list/:facilityID"
                                        exact
                                        isProtectedRoute
                                        component={DeviceList}
                                    />
                                    <RenderRoute
                                        path="/device-list"
                                        exact
                                        isProtectedRoute
                                        component={DeviceList}
                                    />
                                    <Redirect from="*" to="/login" />
                                </Switch>
                            </AppLayout>
                        </Suspense>
                    </BrowserRouter>
                </SnackbarProvider>
            </ThemeProvider>
        </I18nextProvider>
    );
}
