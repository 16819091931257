import { lazy } from 'react';
import PublicRoute from './PublicRoute';

const PrivateRoute = lazy(() => import('./PrivateRoute'));

// @isProtectedRoute : to control that route needs authentication or not
const RenderRoute = ({ isProtectedRoute, ...rest }) => {
    return isProtectedRoute ? (
        <PrivateRoute {...rest} />
    ) : (
        <PublicRoute {...rest} />
    );
};

export default RenderRoute;
