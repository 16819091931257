import {
    AppBar,
    Box,
    IconButton,
    makeStyles,
    Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import smartBeLogo from '../../assets/images/SmartBe-Logo.png';
import alizeHeaderLogo from "../../assets/images/Alize-Header-Logo.png";
import {
    ArrowDownIcon,
    EarthLine,
    LogOutIcon,
    SwitchAccountIcon,
    UserProfileIcon
} from '../../components/icons';
import MenuComp from '../../components/Menu';
import useUserInfo from '../../hooks/swr/useUserInfo';
import { getFirstLetters } from '../../utils/getFirstLetters';
import useGetSideApps from "../../hooks/swr/useGetSideApps";
import { DOMAIN } from "../../utils/constants";

const HeaderMenusWrapper = styled.div`
    display: flex;

    > div {
        & + div {
            margin-left: 16px;
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    appBar: {
        width: '100%',
        minHeight: '76px',
        backgroundColor: '#fff',
        // backgroundColor: 'red',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        justifyContent: 'space-between'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    languageWrapper: {
        '@global': {
            button: {
                minWidth: '113px'
            },
            div: {
                minWidth: '113px'
            }
        }
    },
    profileDropdown: {
        '& > button + div': {
            minWidth: 180
        },
        '& svg': {
            marginRight: 4
        }
    },
    logo: {
        maxWidth: '100%',
        height: 'auto',
        display: 'block',
    }
}));

export default function AppHeader({ drawerToggle }) {
    const classes = useStyles();
    const [generalVariables, setGeneralVariables] = useState({
        department: '',
        language: 'EN'
    });
    const [, setSelectedIndex] = useState(
        localStorage.getItem('i18nextLng') === 'tr' ? 1 : 0
    );
    const { t, i18n } = useTranslation('common');
    const { isLoading, userInfo } = useUserInfo();
    const { companyMutate } = useGetSideApps();
    const history = useHistory();
    const fullName = isLoading ? 'Loading...' : userInfo?.fullName;

    const handleOnClickLanguage = (languageSelection) => {
        setSelectedIndex(() => (languageSelection === 'tr' ? 1 : 0));
        i18n.changeLanguage(languageSelection);
    };

    const logOut = () => {
        // *remove user info from localStorage to prevent redirect when user not authenticated actually
        localStorage.removeItem('user');
        history.push('/');
        companyMutate(null, []);
    };

    const logoByDomainName = () => {
        let logo = '';

        switch (window.location.hostname) {
            case DOMAIN.Alize:
                logo = alizeHeaderLogo;
                break;

            default:
                logo = smartBeLogo;
                break;
        };

        return logo;
    }

    return (
        <AppBar position="fixed">
            <Toolbar className={classes.appBar}>
                <IconButton
                    color="inherit"
                    aria-label="open sidebar"
                    edge="start"
                    onClick={() => drawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <Box >
                    <Link to="/">
                        <Box
                            component="img"
                            className={classes.logo}
                            src={logoByDomainName()}
                            alt=""
                        />
                    </Link>
                </Box>
                <HeaderMenusWrapper>
                    <div className={classes.languageWrapper}>
                        <MenuComp
                            title={generalVariables.language}
                            itemsOnclick={(e) => {
                                setGeneralVariables((prev) => ({
                                    ...prev,
                                    language: e.text
                                }));
                            }}
                            menuKey="language"
                            itemKey="text"
                            activeKey={false}
                            arrowDown={
                                <ArrowDownIcon
                                    fill="#515A6E"
                                    width="20"
                                    height="20"
                                    viewBox="0 -6 12 18"
                                />
                            }
                            titleIconSvg={
                                <EarthLine
                                    fill="#515A6E"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 20 20"
                                />
                            }
                            items={[
                                {
                                    iconSvg: (
                                        <EarthLine
                                            fill="#515A6E"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 20 20"
                                            style={{ marginRight: 8 }}
                                        />
                                    ),
                                    text: 'TR',
                                    changeLanguage: () =>
                                        handleOnClickLanguage('tr')
                                },
                                {
                                    iconSvg: (
                                        <EarthLine
                                            fill="#515A6E"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 20 20"
                                            style={{ marginRight: 8 }}
                                        />
                                    ),
                                    text: 'ENG',
                                    changeLanguage: () =>
                                        handleOnClickLanguage('en')
                                }
                            ]}
                            placement="bottom-end"
                        />
                    </div>

                    <MenuComp
                        title={fullName}
                        titleAvatar={getFirstLetters(fullName)}
                        wrapClass={classes.profileDropdown}
                        items={[
                            {
                                iconSvg: (
                                    <UserProfileIcon
                                        fill="#474D66"
                                        width="24px"
                                        height="24px"
                                        viewBox="-2 0 26 20"
                                    />
                                ),
                                text: 'Profil',
                                renderType: 'link',
                                link: '/user_profile',
                                fontSize: '15px',
                                disabled: true
                            },
                            {
                                iconSvg: (
                                    <SwitchAccountIcon
                                        fill="#515A6E"
                                        width="24px"
                                        height="24px"
                                        viewBox="-2 0 26 20"
                                    />
                                ),
                                text: t('changeCompany'),
                                renderType: 'link',
                                link: '/companies',
                                fontSize: '15px'
                            },
                            {
                                iconSvg: (
                                    <SwitchAccountIcon
                                        fill="#515A6E"
                                        width="24px"
                                        height="24px"
                                        viewBox="-2 0 26 20"
                                    />
                                ),
                                text: t('changeFacility'),
                                renderType: 'link',
                                link: '/facilities',
                                fontSize: '15px'
                            },
                            {
                                iconSvg: (
                                    <LogOutIcon
                                        fill="#515A6E"
                                        width="24px"
                                        height="24px"
                                        viewBox="-2 0 26 20"
                                    />
                                ),
                                text: t('logOut'),
                                fontSize: '15px',
                                logOut
                            }
                        ]}
                        placement="bottom-end"
                    />
                </HeaderMenusWrapper>
            </Toolbar>
        </AppBar>
    );
}
