import axios from 'axios';

const { REACT_APP_API_WORKYBE_BASE_URL } = process.env;

const workybeApi = axios.create({
    headers: {
        Authorization: `Bearer`
    },
    baseURL: REACT_APP_API_WORKYBE_BASE_URL
});

workybeApi.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('user'));

    return {
        ...config,
        headers: {
            Authorization: `Bearer ${token.access_token}`
        }
    };
});

export default workybeApi;
