import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// Create a theme instance.
const defaultTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#3366FF'
        },
        secondary: {
            main: '#C1C4D6'
        },
        success: {
            main: '#82c341'
        },

        error: {
            main: '#fb4b35'
        },
        warning: {
            main: '#ffd044'
        },
        info: {
            main: '#87dae8'
        },
        colorPrimary: '#E3E4E5',
        colorSecondary: '#749FF4',
        colorThirty: '#8D8D8D',
        colorFourty: '#1A1A1A',
        colorFifty: '#9898B1',
        colorSixty: '#515A6E',
        colorError: '#f44336'
    },
    typography: {
        allVariants: {
            color: '#474D66',
            fontFamily: '"Source Sans Pro", sans-serif'
        }
    },
    props: {
        MuiTextField: {
            InputLabelProps: { shrink: true }
        }
    },
    formLabelSize: '1.25rem',
    formFieldSize: {
        padding: '0.46rem 1.25rem 0.57rem',
        minHeight: '1.941rem'
    },
    formFieldFontSize: '1.125rem'
});

// overrides default theme UI with className rules.
defaultTheme.overrides = {
    // * handle button outline issue
    MuiButtonBase: {
        root: {
            outline: 'none !important',

            '&.MuiButton-contained': {
                '&.Mui-disabled': {
                    backgroundColor: '#F4F6FA',
                    color: '#A5ADBA'
                }
            },
            '&.Mui-disabled': {
                '& svg': {
                    fill: 'rgba(0, 0, 0, 0.26)'
                }
            }
        }
    },
    // * overrides timeline
    MuiTimelineItem: {
        missingOppositeContent: {
            '&:before': { flex: 'none !important', content: '' }
        }
    },
    MuiListSubheader: {
        root: {
            lineHeight: '25px'
        }
    },
    MuiTimelineConnector: {
        root: {
            marginTop: -defaultTheme.spacing(1),
            marginBottom: -defaultTheme.spacing(1)
        }
    },
    MuiTimelineContent: {
        root: {
            paddingLeft: defaultTheme.spacing(0.5),
            paddingTop: defaultTheme.spacing(0.25)
        }
    },
    /*  MuiTooltip: {
        tooltip: {
            fontSize: '0.938rem',
            backgroundColor: '#172B4D',
            color: '#fff',
            padding: '8px 16px 10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.200691)'
        },
        arrow: {
            '&::before': {
                backgroundColor: '#172B4D'
            }
        }
    }, */
    MuiTooltip: {
        tooltip: {
            fontSize: '1.172rem',
            backgroundColor: '#0054A5',
            color: '#fff',
            padding: '8px 16px 10px',
            boxShadow: '0px 4px 8px rgba(0, 84, 165, 0.15);',
            maxWidth: 344
        },
        arrow: {
            '&::before': {
                backgroundColor: '#0054A5'
            }
        }
    },
    MuiInputAdornment: {
        root: {
            width: '1.875rem',
            height: '1.875rem',

            '&.MuiInputAdornment-positionEnd': {
                paddingRight: '40px'
            },
            '& .MuiButtonBase-root': {
                backgroundColor: 'transparent',
                width: '24px',
                height: '24px',
                padding: '4px',
                marginLeft: '4px'
            },
            '& > .MuiTypography-root': {
                color: '#1376D8',
                cursor: 'pointer'
            },
            '& .MuiSvgIcon-root': {
                fontSize: '1.406rem'
            }
        }
    },
    MuiSelect: {
        root: {
            padding: `${defaultTheme.formFieldSize.padding}`,
            minHeight: `${defaultTheme.formFieldSize.minHeight} !important`,
            lineHeight: `${defaultTheme.formFieldSize.minHeight} !important`
        },
        select: {
            '&:focus': {
                backgroundColor: 'transparent'
            }
        },
        icon: {
            color: '#8F95B2',
            right: '10px'
        }
    },
    MuiButton: {
        root: {
            outline: 'none !important',
            textTransform: 'none',
            padding: '0.50rem 1.25rem 0.419rem',
            fontSize: '1.172rem',
            fontWeight: 600,
            borderRadius: '5px',
            minWidth: 'initial',

            '&.danger': {
                background: '#DE350B',
                color: '#fff',

                '&:hover': {
                    background: '#b72805'
                }
            }
        },
        startIcon: {
            marginLeft: 0
        },
        contained: {
            backgroundColor: '#fff',
            color: '#1376D8',

            '&:hover': {
                boxShadow:
                    '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                backgroundColor: '#fff'
            }
        },
        containedSizeSmall: {
            padding: '2px 8px'
        },
        containedSizeLarge: {
            fontSize: '1.172rem',
            paddingTop: '7.75px',
            paddingBottom: '10px'
        },
        outlinedSizeLarge: {
            '&.iconBtn': {
                padding: '10px'
            }
        },
        outlinedSecondary: {
            '&:hover, &.active': {
                borderColor: `${defaultTheme.palette.primary.main}`,

                '& svg': {
                    fill: `${defaultTheme.palette.primary.main}`
                }
            }
        },
        containedSecondary: {
            backgroundColor: '#F4F6FA',
            color: '#474D66',

            '&:hover': {
                backgroundColor: '#0054A5',
                color: '#F4F6FA'
            }
        },
        sizeSmall: {
            fontSize: '1.25rem',
            lineHeight: '1.65rem',
            fontWeight: 'normal'
        }
    },
    MuiOutlinedInput: {
        root: {
            '& legend': {
                fontSize: '0.90em'
            }
        },
        InputMarginDense: {
            padding: `${defaultTheme.formFieldSize.padding}`,
            minHeight: `${defaultTheme.formFieldSize.minHeight}`
        },
        multiline: {
            padding: 0
        }
    },
    MuiFormControl: {
        root: {
            // marginBottom: "2rem",

            '&:hover': {
                '& .MuiFormLabel-root:not(.Mui-disabled)': {
                    color: `${defaultTheme.palette.colorSecondary}`
                },

                '& .MuiInput-root:not(.Mui-disabled)': {
                    boxShadow: `inset 0px 0px 0px 1px ${defaultTheme.palette.colorSecondary}`
                },

                '& .MuiButtonBase-root': {
                    /* backgroundColor: '#F5F6F8', */
                    borderRadius: '4px',
                    color: `${defaultTheme.palette.colorSecondary}`
                }
            },

            '& .error': {
                color: `${defaultTheme.palette.colorError}`
            },

            '&.Mui-error .MuiAutocomplete-inputRoot': {
                boxShadow: `inset 0px 0px 0px 2px ${defaultTheme.palette.error.main}`,
                backgroundColor: '#FEF1F2'
            }
        }
    },
    MuiInput: {
        root: {
            borderRadius: '5px',
            boxShadow: `inset 0px 0px 0px 1px ${defaultTheme.palette.colorPrimary}`,
            background: '#FCFCFC',

            '&$focused': {
                boxShadow: `inset 0px 0px 0px 2px ${defaultTheme.palette.primary.main} !important`,

                '& .MuiButtonBase-root': {
                    color: `${defaultTheme.palette.primary.main}`
                }
            },
            '&$error': {
                boxShadow: `inset 0px 0px 0px 2px ${defaultTheme.palette.error.main}`,
                backgroundColor: '#FEF1F2'
            },
            '& .fieldErrorIcon': {
                display: 'none'
            },
            '&$error .fieldErrorIcon': {
                display: 'flex'
            },
            '&.MuiInputBase-adornedStart': {
                paddingLeft: '14px'
            },
            // '&.MuiInputBase-adornedEnd': {
            //   paddingRight: "11px",
            // },
            '&.MuiInputBase-adornedStart input': {
                paddingLeft: '8px'
            },
            '&.Mui-disabled': {
                backgroundColor: '#F4F6FA',
                boxShadow: `inset 0px 0px 0px 1px #E0E0E0`
            }
        },
        // focused: {
        //   root: {
        //     boxShadow: `inset 0px 0px 0px 2px ${defaultTheme.palette.colorSecondary}`
        //   }
        // },
        formControl: {
            '&::before, &::after': {
                display: 'none'
            }
        },
        input: {
            color: `${defaultTheme.palette.colorFifty}`,
            border: 0,
            borderRadius: 0,
            padding: `${defaultTheme.formFieldSize.padding}`,
            minHeight: `${defaultTheme.formFieldSize.minHeight}`,
            background: 'transparent',

            '&::placeholder': {
                color: `${defaultTheme.palette.colorFifty}`
            },
            '&:-ms-input-placeholder': {
                color: `${defaultTheme.palette.colorFifty}`
            },
            '&::-ms-input-placeholder': {
                color: `${defaultTheme.palette.colorFifty}`
            },
            '&.Mui-disabled': {
                color: '#8F95B2'
            }
        }
    },
    MuiCheckbox: {
        root: {
            color: `${defaultTheme.palette.colorPrimary}`
        }
    },
    MuiFormHelperText: {
        root: {
            marginBottom: 0,

            '&$error': {
                color: '#DC3544'
            }
        }
    },
    MuiFormLabel: {
        root: {
            fontSize: `${defaultTheme.formLabelSize}`,
            color: `${defaultTheme.palette.colorSixty}`,
            fontWeight: 600,

            '&$focused': {
                color: `${defaultTheme.palette.primary.main} !important`
            },
            '& + .MuiInput-formControl, & + .MuiAutocomplete-root': {
                marginTop: 0
            }
        }
    },
    MuiInputBase: {
        root: {
            fontSize: `${defaultTheme.formFieldFontSize}`
        },
        input: {
            '&.MuiOutlinedInput-input': {
                padding: `${defaultTheme.formFieldSize.padding}`,
                minHeight: `${defaultTheme.formFieldSize.minHeight}`
            }
        }
    },
    MuiAutocomplete: {
        input: {
            padding: '0 !important'
        },
        inputRoot: {
            padding: `${defaultTheme.formFieldSize.padding} !important`,

            '&[class*="MuiOutlinedInput-root"]': {
                padding: `${defaultTheme.formFieldSize.padding}`,
                minHeight: `${defaultTheme.formFieldSize.minHeight}`
            },
            '&[class*="MuiInput-root"]': {
                paddingBottom: '0.782rem'
            },

            '& .MuiIconButton-root': {
                width: '24px',
                height: '24px',
                padding: '4px',
                marginLeft: '4px',
                backgroundColor: '#EDEEF0'
            }
        },
        endAdornment: {
            right: '16px',
            top: '50%',
            transform: 'translateY(-50%)'
        },
        option: {
            color: '#9898B1',

            '&:hover': {
                backgroundColor: '#F5F5F5',
                color: '#515A6E'
            },

            '&[aria-selected="true"]': {
                backgroundColor: '#E9F3FF',
                color: `${defaultTheme.palette.primary.main}`
            }
        },
        paper: {
            /* border: `1px solid ${defaultTheme.palette.colorPrimary}`, */
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)'
        },
        clearIndicator: {
            '& .MuiSvgIcon-root': {
                fontSize: '1.016rem'
            }
        }
    },
    MuiIconButton: {
        root: {
            borderRadius: '4px',
            padding: '6px'
        },
        colorPrimary: {
            '&:hover': {
                backgroundColor: '#F4F6FA'
            }
        }
    },
    MuiInputLabel: {
        outlined: {
            transform: 'translate(14px, 15px) scale(1)',

            '&$focused': {
                fontSize: `${defaultTheme.formLabelSize} !important`
            }
        },
        formControl: {
            position: 'initial'
        },
        shrink: {
            transform: 'translate(0, -0.6rem)',
            paddingTop: '1rem'
        }
    },
    MuiPaper: {
        rounded: {
            borderRadius: '5px'
        }
    },
    MuiTypography: {
        h1: {
            fontSize: '2.813rem',

            [defaultTheme.breakpoints.up('lg')]: {
                fontSize: '2.813rem'
            }
        },
        h3: {
            fontSize: '1.875rem',

            [defaultTheme.breakpoints.up('lg')]: {
                fontSize: '1.875rem'
            }
        },
        body1: {
            fontSize: '1.25rem'
        },
        body2: {
            fontSize: '1.094rem'
        }
    },
    MuiToolbar: {
        gutters: {
            [defaultTheme.breakpoints.up('lg')]: {
                padding: '0 32px'
            }
        }
    },
    MuiTable: {
        root: {
            borderCollapse: 'separate',
            borderSpacing: '0 8px'
        }
    },
    MuiTableRow: {
        root: {
            '&:hover': {
                backgroundColor: 'transparent !important'
            },

            '& .MuiTableCell-root:first-child': {
                borderLeft: '1px solid #C1C4D6',
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5
            },
            '& .MuiTableCell-root:last-child': {
                borderRight: '1px solid #C1C4D6',
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5
            }
        }
    },
    MuiTableCell: {
        root: {
            borderBottom: '1px solid #C1C4D6',
            borderTop: '1px solid #C1C4D6',
            fontSize: '1.25rem',
            padding: '17.5px 16px',

            '& .MuiTableSortLabel-root': {
                color: '#9898B1',

                '&.MuiTableSortLabel-active': {
                    color: defaultTheme.palette.primary.main
                }
            }
        },
        body: {
            color: '#474D66'
        },
        head: {
            fontWeight: '600',
            color: '#9898B1',
            border: '0 !important',
            paddingTop: '4px',
            paddingBottom: '4px'
        }
    },
    MuiTablePagination: {
        root: {
            '& .MuiSelect-root': {
                fontSize: '1.172rem',
                color: '#9898B1'
            }
        },
        caption: {
            fontSize: '1.172rem',
            color: '#9898B1'
        }
    },
    MuiDialogTitle: {
        root: {
            padding: '24px 32px'
        }
    },
    MuiDialogContent: {
        root: {
            padding: '0 32px 32px 32px'
        }
    },
    MuiAccordion: {
        root: {
            boxShadow: 'none',
            border: 0,

            '&::before': {
                display: 'none'
            },

            '& .MuiCollapse-container': {
                backgroundColor: '#fff'
            },

            '&.Mui-expanded': {
                marginTop: 0,
                marginBottom: 8,

                '& svg': {
                    fill: '#fff'
                }
            }
        }
    },
    MuiRadio: {
        root: {
            color: '#D8DAE5',
            padding: 0,

            '& .MuiSvgIcon-root': {
                width: '1.875rem',
                height: '1.875rem'
            },

            '&.Mui-checked': {
                color: `${defaultTheme.palette.primary.main}`
            }
        }
    },
    MuiAccordionSummary: {
        root: {
            backgroundColor: '#F4F6FA',
            minHeight: 50,
            borderRadius: '5px',
            paddingLeft: 30,
            paddingRight: 30,

            '&.Mui-expanded': {
                minHeight: 50,
                backgroundColor: '#0054A5',
                color: '#fff'
            }
        },
        content: {
            fontSize: '1.25rem',

            '&.Mui-expanded': {
                margin: '12px 0'
            },

            '& MuiSvgIcon-root, svg': {
                marginRight: 8
            }
        }
    },
    MuiChip: {
        icon: {
            marginLeft: 0,
            marginRight: 0
        },
        root: {
            margin: 3
        }
    }
};

const coreTheme = responsiveFontSizes(defaultTheme);

export default coreTheme;
