/* import axios from 'axios'; */
import workybeApi from '../../api/workybeApi';

const workybeApiFetcher = (...args) =>
    workybeApi.get(...args).then(({ data }) => data);

export default workybeApiFetcher;

/* const v2ApiFetcher = async (url, token) => {
    try {
        const res = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return res.data;
    } catch (error) {
        // TODO: should do sth with error
        console.log('👀 => v2ApiFetcher => error', error);
        return null;
    }
};

export default v2ApiFetcher; */
