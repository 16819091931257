/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Button,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Box,
    Typography,
    Tooltip
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import clsx from 'clsx';

const MenuCompWrapper = styled.div`
    /* content: ''; */
`;

const TypographyStyle = styled(Typography)`
    position: relative;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 50%;
        opacity: 0.5;

        ${(props) =>
            props.backgroundColor &&
            css`
                background: ${props.backgroundColor};
            `}
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        // content: 'asd',
        position: 'relative'
    },
    button: {
        height: '40px',
        background: '#fff',
        boxShadow: '0px 2px 3px rgba(16, 24, 64, 0.1)',
        borderRadius: '5px',
        border: '1px solid transparent',

        '&:hover': {
            background: '#fff',
            boxShadow: '0px 4px 8px rgba(16, 24, 64, 0.16)'
        },

        '&[aria-controls]': {
            border: '1px solid #3366FF;',
            color: '#3366FF',

            '@global': {
                'span p': {
                    color: '#3366FF'
                },
                'span svg': {
                    fill: '#3366FF'
                },
                'span > p:first-child': {
                    background: 'rgba(41, 109, 241, 0.3)'
                }
            }
        }
    },
    menu: {
        marginTop: '4px',
        background: '#fff',
        border: '1px solid #E9F3FF',
        boxShadow: '0px 4px 8px rgba(16, 24, 64, 0.16)',
        borderRadius: '5px',
        width: '100%',
        zIndex: 5,

        '@global': {
            '> div': {
                boxShadow: 'none'
            }
        }
    },
    title: {
        fontSize: '15px',
        marginLeft: theme.spacing(1)
    },
    avatar: {
        fontSize: '15px',
        padding: '5px',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        lineHeight: '20px',
        textAlign: 'center'
    },
    text: {
        fontSize: '14px'
        // marginLeft: theme.spacing(1)
    },
    link: {
        textDecoration: 'none',
        display: 'flex'
    },
    titleAvatar: {
        fontSize: '15px',
        padding: '5px',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        lineHeight: '19px',
        background: '#CBCBCB',
        textAlign: 'center',
        position: 'relative',

        '@global': {
            '> div': {
                marginLeft: '-1px'
            }
        }
    },
    arrowDown: {
        marginLeft: theme.spacing(2),
        width: '22px',
        height: '22px',
        background: '#F5F6F8',
        borderRadius: '4px'
    },
    avatarOutBg: {
        padding: '14px',
        borderRadius: '50%',
        width: '48px',
        height: '48px',
        textAlign: 'center'
    },
    menuList: {
        paddingLeft: 8,
        paddingRight: 8,

        '@global': {
            '.MuiMenuItem-root': {
                borderRadius: 4
            },

            '.MuiListItem-gutters': {
                paddingLeft: 10,
                paddingRight: 10
            },

            '.MuiListItem-button:hover': {
                backgroundColor: '#F2F4F7'
            },

            '> * + *': {
                marginTop: '8px'
            }
        }
    },
    shortCutMenuItem: {
        flex: '1 0 33.33%',
        width: '60px',
        padding: 0,
        justifyContent: 'center',

        '& > .shortCutMenuItemInner': {
            display: 'flex',
            flexDirection: 'column',
            padding: '16px 0',
            textDecoration: 'none',
            justifyContent: 'center',
            alignItems: 'center',

            '& div': {
                marginBottom: '8px'
            }
        }
    },
    horizontalType: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '8px'
    }
}));

// const MenuComp = ({ items, title, ...rest }) => {
const MenuComp = ({
    title,
    titleIconSvg,
    titleAvatar,
    arrowDown,
    placement,
    type,
    items,
    itemsOnclick,
    menuKey,
    itemKey,
    itemKey2,
    activeKey,
    disabled,
    wrapClass,
    btnClass,
    tooltip
}) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const renderMenuItem = (item) => {
        let renderItem = {};

        switch (item.renderType) {
            case 'shortCutLink':
                renderItem = (
                    <MenuItem
                        key={item.text}
                        className={classes.shortCutMenuItem}
                        onClick={(e) => {
                            if (itemsOnclick) {
                                itemsOnclick(item);
                            }

                            handleClose(e);
                        }}
                    >
                        <Link to={item.link} className="shortCutMenuItemInner">
                            {item.iconSvg && item.bgColor ? (
                                <Box
                                    className={classes.avatarOutBg}
                                    style={{
                                        background: item.bgColor
                                    }}
                                >
                                    {item.iconSvg}
                                </Box>
                            ) : (
                                item.iconSvg
                            )}

                            {item.avatar && (
                                <TypographyStyle
                                    className={classes.avatar}
                                    backgroundColor={item.avatarBg}
                                    style={{
                                        color: item.avatarColor,
                                        backgroundColor: item.avatarBg,
                                        fontSize: item.fontSize
                                    }}
                                >
                                    {item.avatar}
                                </TypographyStyle>
                            )}

                            {item.text && (
                                <Typography
                                    className={classes.text}
                                    style={{
                                        color: item.textColor,
                                        fontSize: item.fontSize,
                                        fontWeight: item.fontWeight
                                    }}
                                >
                                    {item.text}
                                </Typography>
                            )}
                        </Link>
                    </MenuItem>
                );
                break;
            case 'shortCut':
                renderItem = (
                    <MenuItem
                        key={item.text}
                        className={classes.shortCutMenuItem}
                        onClick={(e) => {
                            if (itemsOnclick) {
                                itemsOnclick(item);
                            }

                            handleClose(e);
                        }}
                    >
                        <div className="shortCutMenuItemInner">
                            {item.iconSvg && item.bgColor ? (
                                <Box
                                    className={classes.avatarOutBg}
                                    style={{
                                        background: item.bgColor
                                    }}
                                >
                                    {item.iconSvg}
                                </Box>
                            ) : (
                                item.iconSvg
                            )}

                            {item.avatar && (
                                <TypographyStyle
                                    className={classes.avatar}
                                    backgroundColor={item.avatarBg}
                                    style={{
                                        color: item.avatarColor,
                                        backgroundColor: item.avatarBg,
                                        fontSize: item.fontSize
                                    }}
                                >
                                    {item.avatar}
                                </TypographyStyle>
                            )}

                            {item.text && (
                                <Typography
                                    className={classes.text}
                                    style={{
                                        color: item.textColor,
                                        fontSize: item.fontSize,
                                        fontWeight: item.fontWeight
                                    }}
                                >
                                    {item.text}
                                </Typography>
                            )}
                        </div>
                    </MenuItem>
                );
                break;
            case 'link':
                renderItem = (
                    <MenuItem
                        key={item.text}
                        onClick={(e) => {
                            if (itemsOnclick) {
                                itemsOnclick(item);
                            }

                            handleClose(e);
                        }}
                        disabled={item.disabled}
                    >
                        <Link to={item.link} className={classes.link}>
                            {item.iconSvg && item.bgColor ? (
                                <Box
                                    className={classes.avatarOutBg}
                                    style={{
                                        background: item.bgColor
                                    }}
                                >
                                    {item.iconSvg}
                                </Box>
                            ) : (
                                item.iconSvg
                            )}

                            {item.avatar && (
                                <TypographyStyle
                                    className={classes.avatar}
                                    backgroundColor={item.avatarBg}
                                    style={{
                                        color: item.avatarColor,
                                        backgroundColor: item.avatarBg,
                                        fontSize: item.fontSize
                                    }}
                                >
                                    {item.avatar}
                                </TypographyStyle>
                            )}

                            {item.text && (
                                <Typography
                                    className={classes.text}
                                    style={{
                                        color: item.textColor,
                                        fontSize: item.fontSize,
                                        fontWeight: item.fontWeight
                                    }}
                                >
                                    {item.text}
                                </Typography>
                            )}
                        </Link>
                    </MenuItem>
                );
                break;
            default:
                renderItem = (
                    <MenuItem
                        key={item.text}
                        onClick={(e) => {
                            if (item.logOut) {
                                item.logOut();
                            }

                            if (item.changeLanguage) {
                                item.changeLanguage();
                            }

                            if (itemsOnclick) {
                                itemsOnclick(item);
                            }

                            handleClose(e);
                        }}
                    >
                        {item.iconSvg && item.bgColor ? (
                            <Box
                                className={classes.avatarOutBg}
                                style={{
                                    background: item.bgColor
                                }}
                            >
                                {item.iconSvg}
                            </Box>
                        ) : (
                            item.iconSvg
                        )}

                        {item.avatar && (
                            <TypographyStyle
                                className={classes.avatar}
                                backgroundColor={item.avatarBg}
                                style={{
                                    color: item.avatarColor,
                                    fontSize: item.fontSize
                                }}
                            >
                                {item.avatar}
                            </TypographyStyle>
                        )}

                        {item.text && (
                            <Typography
                                className={classes.text}
                                style={{
                                    color: item.textColor,
                                    fontSize: item.fontSize,
                                    fontWeight: item.fontWeight
                                }}
                            >
                                {item.text}
                            </Typography>
                        )}
                    </MenuItem>
                );
                break;
        }

        return renderItem;
    };

    const CustomTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: tooltip?.color || theme.palette.primary.main,
            color: '#fff',
            fontSize: '1.172rem',
            textAlign: 'center'
        },
        arrow: {
            '&::before': {
                backgroundColor: tooltip?.color || theme.palette.primary.main
            }
        }
    }))(Tooltip);

    return (
        <MenuCompWrapper className={`${classes.root} ${wrapClass ?? ''}`}>
            {/*  <CustomTooltip
                title={tooltip?.text || ''}
                placement={tooltip?.position || 'bottom'}
                arrow
                leaveDelay={200000}
            > */}
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={`${classes.button} ${btnClass ?? ''}`}
                disabled={disabled}
            >
                {titleAvatar && (
                    <Typography className={classes.titleAvatar}>
                        <div>{titleAvatar}</div>
                    </Typography>
                )}
                {titleIconSvg && titleIconSvg}
                {title && typeof title === 'object' ? (
                    <Typography className={classes.title}>
                        {title[itemKey]}
                    </Typography>
                ) : (
                    <Typography className={classes.title}>{title}</Typography>
                )}
                {arrowDown && (
                    <Typography
                        className={[classes.arrowDown, 'arrowDownOverride']}
                    >
                        {arrowDown}
                    </Typography>
                )}
            </Button>
            {/* </CustomTooltip> */}
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement={placement}
                className={classes.menu}
            >
                <Grow in>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            {items && (
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={(e) => handleListKeyDown(e)}
                                    className={clsx(classes.menuList, {
                                        [classes.horizontalType]: type
                                    })}
                                >
                                    {items
                                        .filter(
                                            (y) =>
                                                !activeKey &&
                                                title !== y[itemKey]
                                        )
                                        .map((x) => {
                                            return renderMenuItem(x);
                                        })}
                                </MenuList>
                            )}
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            </Popper>
        </MenuCompWrapper>
    );
};

export default MenuComp;
