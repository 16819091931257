import { createMuiTheme, withStyles } from '@material-ui/core/styles';

// Create a theme instance.
const globalStyles = createMuiTheme({
    spreadThis: {
        '@global': {
            html: {
                fontSize: '80%'
            },
            '.layoutPaper': {
                marginTop: '1.25rem'
            },
            '.layoutPaperTitle': {
                padding: '1rem',
                borderBottom: '1px solid #eee',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',

                '@media (min-width: 1024px)': {
                    height: '73px',
                    padding: '2.109rem 2.5rem 1.406rem 2.5rem'
                }
            },
            '.layouPaperContent': {
                padding: '1rem',

                '@media (min-width: 1024px)': {
                    padding: '2rem 2.5rem'
                }
            },
            '.dialogClose': {
                position: 'absolute',
                right: 0,
                top: 0
            }
        }
    }
});

const style = () => ({
    ...globalStyles.spreadThis
});

function OverridesMUIGlobal() {
    return null;
}

export default withStyles(style)(OverridesMUIGlobal);
